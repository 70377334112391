<template>
  <div>
    <div class ="row">
      <!-- QR 출력정보 -->
      <c-card title="LBL0003284" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <!-- 인쇄 -->
            <c-btn label="LBL0003285" icon="print" type="check" @btnClicked="print" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="row" id="printJS-form">
            <div
              class="col-md-12 wrapprint">
              <div style="text-align: center;">
                <p class="contentArea">
                  <b>근골격계 설문조사(외부인력)
                  </b>
                </p>
                <br/>
                <p class="contentArea">
                  <b>
                    {{ popupParam.selectData.investigationPlanName }}
                  </b>
                </p>
              </div>
              <div :class="qrClass" style="text-align: center;">
                <vue-qrcode
                  :value="serverName + '/hea/mss/symptomSurveyDetailOuter?heaInvestigationPlanId=' + popupParam.selectData.heaInvestigationPlanId"
                  :options="{ width: 400, errorCorrectionLevel: 'L' }"
                  class="qr-canvas"
                >
                </vue-qrcode>
              </div>
            </div>
          </div>
        </template>
      </c-card>
    </div>
  </div>
</template>

<script>
import printJs from 'print-js';
import VueQrcode from '@chenfengyuan/vue-qrcode';

export default {
  /** attributes: name, components, props, data **/
  name: 'investigation-qr',
  components: {
    VueQrcode,
  },
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          selectData: {
            heaInvestigationPlanId: '',  // 근골격계 조사계획 일련번호
            investigationPlanName: '',  // 조사계획명
          }
        }
      },
    },
  },
  data() {
    return {
      redirectUrl: '',
      component: null,
      tabIndex: 0,
      printItem: [],
      serverName: '',
      prompt: false, 
    };
  },
  computed: {
    qrStyle() {
      return 'margin-bottom: 152px !important';
    },
    qrClass() {
      return 'wrap';
    },
  },
  watch: {},
  /** Vue lifecycle: created, mounted, destroyed, etc **/

  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {},
  beforeDestroy() {},
  /** methods **/
  methods: {
    /** 초기화 관련 함수 **/
    init() {
      this.serverName = this.$store.getters.partnerUrl
    },
    closePopup() {
      this.$emit('closePopup');
    },
    print() {
      printJs({
        printable: 'printJS-form',
        type: 'html',
        font_size: '25px',
        targetStyles: ['*'],
      });
    },
  },
};
</script>
<style>
.qr-canvas {
  width: 100%;
  height: 100%;
  margin: 3 auto;
  display: inline;
}
.wrap {
  padding: 10px;
  width: 500px;
  height: 500px;
  box-sizing: border-box;
}
.wrapprint:last-child > .wrap {
  margin-bottom: 0px !important;
}
.equipmentArea22 {
  align-items: center;
  justify-content: center;
}
.contentArea {
  width: 100%;
  /* height:20px; */
  font-size: 1.5em;
  line-height: 50px;
  display: inline;
  padding-right: 10px;
}
@media print {
  .contentArea {
    width: 100%;
    /* height:20px; */
    font-size: 1.5em;
    display: inline;
    padding-top: 30px;
  }
}
.modelno {
  float: left;
  margin-right: 20px;
}
.zt420 {
  margin-bottom: 152px;
}
.listArea {
  max-height: 318px;
  overflow-y: auto;
}
</style>
